var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.priority && _vm.priority === 1)?_c('div',[_vm._v(" Critical Value Priority 1 identified. Immediate follow-up necessary "),_c('v-btn',{staticClass:"mx-2 alert-icon-big",attrs:{"readonly":"","fab":"","dark":"","small":"","color":"pink"}},[_vm._v("1 ")])],1):_vm._e(),(_vm.priority && _vm.priority > 1 && _vm.priority === 2)?_c('div',[_vm._v(" Critical Value Priority 2 identified. Follow-up within the next business day necessary "),_c('v-btn',{staticClass:"mx-2",attrs:{"readonly":"","fab":"","dark":"","small":"","color":"pink"}},[_vm._v("2 ")])],1):_vm._e()])]),_c('v-container',{attrs:{"fluid":"","grid-list-sm":""}},[_c('div',{staticClass:"text-left pt-2"},[_c('v-select',{attrs:{"items":_vm.filtersAndSorts,"item-text":"Sort / Filter","label":"Sort / Filter","single-line":""},model:{value:(_vm.filterSelected),callback:function ($$v) {_vm.filterSelected=$$v},expression:"filterSelected"}})],1),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"test-result-table",attrs:{"dense":"","items-per-page":_vm.itemsPerPage,"headers":_vm.headers,"search":_vm.search,"items":_vm.filteredResults,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.biomarkerCode",fn:function(props){return [_c('div',{class:{
              'red--text': _vm.resultOutOfRange(props.item),
              'font-weight-bold': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item),
              'purple--text': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item)
            }},[_vm._v(" "+_vm._s(_vm.getBiomarkerCode(props.item))+" ")])]}},{key:"item.biomarkerName",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{
                  'red--text': _vm.resultOutOfRange(props.item),
                  'font-weight-bold': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item),
                  'purple--text': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item)
                }},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getBiomarkerName(props.item))+" "),(_vm.resultIsCritical(props.item) && _vm.isPriority1(props.item))?_c('v-btn',{staticClass:"mx-2 alert-icon-small",attrs:{"readonly":"","fab":"","dark":"","small":"","color":"pink"}},[_vm._v("1")]):_vm._e(),(_vm.resultIsCritical(props.item) && !_vm.isPriority1(props.item) && _vm.isPriority2(props.item))?_c('v-btn',{staticClass:"mx-2 alert-icon-small",attrs:{"readonly":"","fab":"","dark":"","small":"","color":"pink"}},[_vm._v("2")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getBiomarkerName(props.item)))])])]}},{key:"item.biomarkerResult",fn:function(props){return [_c('div',{class:{
              'red--text': _vm.resultOutOfRange(props.item),
              'font-weight-bold': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item),
              'purple--text': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item)
          }},[_vm._v(" "+_vm._s(_vm.getBiomarkerResult(props.item))+" ")])]}},{key:"item.range",fn:function(props){return [_c('div',{class:{
              'red--text': _vm.resultOutOfRange(props.item),
              'font-weight-bold': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item),
              'purple--text': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item)
            }},[_vm._v(" "+_vm._s(_vm.getRange(props.item))+" ")])]}},{key:"item.optimalRanges",fn:function(props){return [_c('div',{class:{
              'red--text': _vm.resultOutOfRange(props.item),
              'font-weight-bold': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item),
              'purple--text': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item)
            }},[_vm._v(" "+_vm._s(_vm.getOptimalRange(props.item))+" ")])]}},{key:"item.priority1Range",fn:function(props){return [_c('div',{class:{
              'red--text': _vm.resultOutOfRange(props.item),
              'font-weight-bold': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item),
              'purple--text': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item)
            }},[_vm._v(" "+_vm._s(_vm.getPriority1Range(props.item))+" ")])]}},{key:"item.priority2Range",fn:function(props){return [_c('div',{class:{
              'red--text': _vm.resultOutOfRange(props.item),
              'font-weight-bold': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item),
              'purple--text': _vm.resultOutOfRange(props.item) && _vm.resultIsCritical(props.item)
            }},[_vm._v(" "+_vm._s(_vm.getPriority2Range(props.item))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }