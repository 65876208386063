









































































import {readHasReviewerAcccess} from '@/store/main/getters';
import {Component, Prop, Vue} from 'vue-property-decorator';
import moment from 'moment-timezone';

/**
 * @class that shows a timeline with the existing comments in a TestResult
 * comments are built with the following format:
 * comment | commenterId | date
 */
@Component
export default class TestResultComments extends Vue {
  @Prop({type: Array}) public items: any[];
  public panelIndex = 0;
  public updatedComment = '';
  public dialog = true;
  public isReviewer = false; // if it is reviewer, the screen will be configured with less information

  public mounted() {
    this.fetchData();
  }

  public async fetchData() {
    this.isReviewer = (await readHasReviewerAcccess(this.$store)) || false;
  }

  /**
   * @returns the text to be displayed in the timeline
   * by extracting the comment from the item
   * the text is all waht is before the previouse to
   */
  public getItemText(item: any) {
    return item?.comment;
  }

  /**
   * @returns the text to be displayed in the timeline
   * by extracting the comment from the item
   */
  public getItemCreatorId(item: any) {
    return item.creatorId ?? '';
  }

  /**
   * gets the comment date by extracting
   * its value from the string composed of the comment | commenterId | date
   */
  public getItemDate(item: any) {
    const date = item.updatedAt ? item.updatedAt : item.createdAt;
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  /**
   * saves the changes to the current comment by emitting an event
   */
  public saveComment(item: any, updatedComment: string) {
    if (updatedComment && updatedComment.length > 0) {
      this.$emit('test-result-comments:updated', item, updatedComment);
    }
    this.updatedComment = '';
  }
}
