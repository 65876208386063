










































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ConfirmTestResultApproval extends Vue {

  public dialog = false;
  public approveComment: string = '';

  public submitCommentAndApprove() {
    this.$emit('approve-test-result', this.approveComment);
    this.dialog = false;
  }
}
