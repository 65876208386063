






































































































































































import { ITestResult } from '@/interfaces/requisitions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class TestResultsTable extends AppComponent {
  @Prop({ type: [Object, Array] }) public testResults: ITestResult[];
  @Prop({ type: [Object, Boolean] }) public loading: boolean;
  @Prop({ type: [Number, Boolean] }) public priority: boolean;
  @Prop({ type: Number, default: -1 }) public itemsPerPage: number;

  public originalSorting: ITestResult[] = [];
  public filteredResults: ITestResult[] = [];
  public filterSelected: string = '';
  public search: string = '';

  public headers = [
    {
      text: 'Quest biomarker code',
      align: 'left',
      sortable: false,
      class: ['subheading', 'black--text'],
      value: 'biomarkerCode',
    },
    {
      text: 'Biomarker',
      align: 'left',
      sortable: false,
      class: ['subheading', 'black--text'],
      value: 'biomarkerName',
      width: 300,
    },
    {
      text: 'Result',
      align: 'left',
      sortable: false,
      class: ['subheading', 'black--text'],
      value: 'biomarkerResult',
    },
    {
      text: 'Quest Reference Range',
      align: 'center',
      sortable: false,
      class: ['subheading', 'black--text'],
      value: 'range',
    },
    {
      text: 'Optimal Ranges',
      align: 'center',
      sortable: false,
      class: ['subheading', 'black--text'],
      value: 'optimalRanges',
    },
    {
      text: 'Priority range 1',
      align: 'center',
      sortable: false,
      class: ['subheading', 'black--text'],
      value: 'priority1Range',
    },
    {
      text: 'Priority range 2',
      align: 'center',
      sortable: false,
      class: ['subheading', 'black--text'],
      value: 'priority2Range',
    },
  ];

  public filtersAndSorts = [
    'By name',
    'By code',
    'By critical value',
    'By out of range',
    'Critical value only',
    'Out of range Only',
  ];

  @Watch('testResults', { immediate: true })
  public onResultsChange() {
    this.resetSortingAndFiltering();
  }

  public resetSortingAndFiltering() {
    this.originalSorting = [...this.testResults];
    this.filteredResults = this.originalSorting;
    Vue.set(this, 'filterSelected', 'By code');
  }

  /**
   * adds the biomarker units if they exist to the given
   * string
   */
  public addUnits(item, str: string) {
    if (item.biomarkerUnit && /\d/.test(str)) {
      return `${str} ${item.biomarkerUnit}`;
    } else {
      return str;
    }
  }

  public getBiomarkerName(item: ITestResult) {
    return item.biomarkerName || 'N/A';
  }

  public getBiomarkerCode(item: ITestResult) {
    return item.biomarkerCode || '';
  }

  public getBiomarkerResult(item: ITestResult) {
    if (item.biomarkerResult) {
      return this.addUnits(item, `${item.biomarkerResult}`);
    } else {
      return 'N/A';
    }
  }

  public getRange(item: ITestResult) {
    if (item.range && item.range.length > 0) {
      return this.addUnits(item, `${item.range}`);
    }
  }

  public getOptimalRange(item: ITestResult) {
    if (item.optimalRanges && item.optimalRanges.length > 0) {
      return this.addUnits(item, `${item.optimalRanges}`);
    }
  }

  public getPriority1Range(item: ITestResult) {
    if (item.priority1Range && item.priority1Range.length > 0) {
      return this.addUnits(item, `${item.priority1Range}`);
    }
  }

  public getPriority2Range(item: ITestResult) {
    if (item.priority2Range && item.priority2Range.length > 0) {
      return this.addUnits(item, `${item.priority2Range}`);
    }
  }

  /**
   * calculates if result is out of range
   * If the result is critical we check if it is priority 1 or 2 and if it is out of range
   * under the priority logic
   * if not, we check if the isOutOfRange flag is turned on
   */
  public resultOutOfRange(item) {
    if (item.resultCritical) {
      if ((item.isPriority1 || item.isPriority2) && (item.isOutOfRangePriority1 || item.isOutOfRangePriority2)) {
        return true;
      }
    }

    return item.isOutOfRange;
  }

  public biomarkerIsCritical(item) {
    return item.biomarkerCritical;
  }

  public resultIsCritical(item) {
    return item.resultCritical && item.biomarkerCritical;
  }

  public isPriority1(item) {
    return item.isPriority1 && item.isOutOfRangePriority1;
  }

  public isPriority2(item) {
    return item.isPriority2 && item.isOutOfRangePriority2;
  }

  /**
   * Sorts the given results list by result critical values first
   * For a result to be critical, it must be flagged as critical and  the biomarker should
   * be critical as well.
   */
  public sortTableByResultCriticalValue(results) {
    if (results && results.length > 0) {
      return results.sort((a, b) => {
        if (this.resultIsCritical(a) && !this.resultIsCritical(b)) {
          return -1;
        } else if (!this.resultIsCritical(a) && this.resultIsCritical(b)) {
          return 1;
        } else if (this.resultIsCritical(a) && this.resultIsCritical(b)) {
            return 0;
        } else {
          return 0;
        }
      });
    } else {
      return [];
    }
  }

  /**
   * Sorts the table by result out of range values first.
   */
  public sortTableByResultOutOfRange(results) {
    if (results && results.length > 0) {
      return results.sort((a, b) => {
        if (this.resultOutOfRange(a) && !this.resultOutOfRange(b)) {
          return -1;
        } else if (!this.resultOutOfRange(a) && this.resultOutOfRange(b)) {
          return 1;
        } else if (this.resultOutOfRange(a) && this.resultOutOfRange(b)) {
            return 0;
        } else {
          return 0;
        }
      });
    } else {
      return [];
    }
  }

  /**
   * Sorts the table by biomarker name
   */
  public sortTableByBiomarkerName(results) {
    if (results && results.length > 0) {
      return results.sort((a, b) => {
        if ((a.biomarkerName ? a.biomarkerName : '') < (b.biomarkerName ? b.biomarkerName : '')) {
          return -1;
        } else if ((a.biomarkerName ? a.biomarkerName : '') > (b.biomarkerName ? b.biomarkerName : '')) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      return [];
    }
  }

  /**
   * Sorts the table by biomarker code
   */
  public sortTableByBiomarkerCode(results) {
    if (results && results.length > 0) {
      return results.sort((a, b) => {
        if ((a.biomarkerCode ? a.biomarkerCode : '') < (b.biomarkerCode ? b.biomarkerCode : '')) {
          return -1;
        } else if ((a.biomarkerCode ? a.biomarkerCode : '') > (b.biomarkerCode ? b.biomarkerCode : '')) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      return [];
    }
  }

  @Watch('filterSelected', { immediate: true })
  public onFilterSelectedChange(newValue: string) {
    const results = [...this.testResults]; // make a copy of the original array
    if (newValue === 'By name') {
      this.filteredResults = this.sortTableByBiomarkerName(results);
    } else if (newValue === 'By critical value') {
      this.filteredResults = this.sortTableByResultCriticalValue(results);
    } else if (newValue === 'By out of range') {
      this.filteredResults = this.sortTableByResultOutOfRange(results);
    } else if (newValue === 'Critical value only') {
      this.filteredResults = results.filter((result) => this.resultIsCritical(result));
    } else if (newValue === 'Out of range Only') {
      this.filteredResults = results.filter((result) => this.resultOutOfRange(result));
    } else if (newValue === 'By code') {
      this.filteredResults = this.sortTableByBiomarkerCode(results);
    } else {
      this.filteredResults = results;
    }
  }
}
